import { useState, useEffect } from "react";
import * as React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CContainer, CRow, CCol, CSpinner } from '@coreui/react'
import AddData from "../views/AddData";
import ShowData from "../views/ShowData";
import Header from './Header';
import Sidebar from "./Sidebar";
import Profile from '../views/Profile';
import About from '../views/About';
import Support from '../views/Support';
import Login from "../views/Login";
import Register from "../views/Register";
import "../App.css";
import ForgotPassword from "../views/ForgotPassword";
import ResetPassword from "../views/ResetPassword";
import Dashboard from "../views/Dashboard";
import RegisterSuccess from "../views/RegisterSuccess";
import AllCallers from '../views/callers/AllCallers';
import ActivatedCallers from '../views/callers/ActivatedCallers';
import DeactivatedCallers from '../views/callers/DeactivatedCallers';
import Applications from "../views/Applications";
import CallerData from "../views/callers/CallerData";
import CallerCallData from "../views/callers/CallerCallData";
import CallData from "../views/callers/CallData";

export const userContext = React.createContext();

const Routing = () => {

    const [isToken, setIsToken] = useState('');
    const [isLoading, setIsLoading] = useState(true)

    const GetToken = () => {
        const TOKEN = localStorage.getItem("session_token");
        setIsToken(TOKEN)
        setIsLoading(false)
    }

    const TOKEN = localStorage.getItem("session_token");

    const [userDetails, setUserDetails] = useState('')
    const validToken = async() => {
        const TOKEN = localStorage.getItem("session_token");
        if (isToken) {
            let userURL = await fetch("https://api.indgiants.in/api/user", {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${TOKEN}`
            },
            }) 
            userURL = await userURL.json()
            const callerDetails = userURL;
            setUserDetails(callerDetails)
        } else {
            return
        }
    }

    const [Loading, setLoading] = useState(true)

    const [gettingCallerDetails, setGettingCallerDetails] = useState([])

    useEffect(() => {
        validToken();
    }, [isToken])

    useEffect(() => {
        setGettingCallerDetails(userDetails)
    }, [userDetails])

    useEffect(() => {
        GetToken();
    }, [])

    return (
        <BrowserRouter>
            <CContainer fluid className="routing-container">
                    {
                        isLoading ?
                        <CSpinner />
                        :
                        <>
                            {
                                isToken ?
                                    <CRow>
                                        <CCol className="sidebar-column col-md-3">
                                            <userContext.Provider value={gettingCallerDetails}>
                                                <Sidebar />
                                            </userContext.Provider>
                                        </CCol>
                                        <CCol className="content-column col-md-9" style={{ paddingLeft: "0" }}>
                                            <Header />
                                            <userContext.Provider value={gettingCallerDetails}>
                                                <Routes>
                                                        <Route index element={<Applications />} />
                                                        <Route path="*" element={<Applications />} />
                                                        <Route path="applications" element={<Applications />} />
                                                        <Route path="show-:app" element={<ShowData />} />
                                                        <Route path="all-callers" element={<AllCallers />} />
                                                        <Route path="caller-call-data" element={<CallerCallData />} />
                                                        <Route path="caller-call-data/call-data" element={<CallData />} />
                                                        <Route path="activated-callers" element={<ActivatedCallers />} />
                                                        <Route path="deactivated-callers" element={<DeactivatedCallers />} />
                                                        <Route path="all-callers/caller-data" element={<CallerData />} />
                                                        <Route path="profile" element={<Profile />} />
                                                        <Route path="about" element={<About />} />
                                                        <Route path="support" element={<Support />} />
                                                </Routes>
                                            </userContext.Provider>
                                        </CCol>
                                    </CRow>
                                :
                                    <CRow>
                                        <CCol xs={12} className="content-column">
                                            <Routes>
                                                <Route path="*" element={<Login />} />
                                                {/* <Route path="/register" element={<Register />} /> */}
                                                {/* <Route path="/forgot-password" element={<ForgotPassword />} />
                                                <Route path="/reset/:passcode" element={<ResetPassword />} />
                                                <Route path="/register-success" element={<RegisterSuccess />} /> */}
                                            </Routes>
                                        </CCol>
                                    </CRow>
                            }
                        </>
                    }
            </CContainer>
        </BrowserRouter>
    )
}

export default Routing;