import React, { useState, useEffect } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable,
  CTableBody,
  // CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
  CPagination,
  CPaginationItem,
  CFormSwitch,
  CSpinner
} from '@coreui/react';
import { Link } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )

const AllCallers = () => {

  const caller_email = "sayi1996m@gmail.com";
  // const [Email, setEmail] = useState('')
  // setEmail(caller)
  // Getting Data from:
  // Onloading: getData() function
  // NextPage: nextPage() function
  // Previous Page: previousPage() function
  // Datewise: showDatewiseData() function
  const [allData, setallData] = useState([])
  const [chooseDisplay, setchooseDisplay] = useState('spinnerDisplay')

  //For Showing All Data
  const getData = async () => {
    let userData = await fetch("https://api-admin.indgiants.in/api/callers");
    userData = await userData.json();
    let limitedData = userData.data;
    setallData(limitedData)

    // Set Next Page URL, It send the link to Button
    let nextData = userData.next_page_url;
    setnextPagination(nextData)

    // Set Prev Page URL, It send the link to Button
    let previousData = userData.prev_page_url;
    setpreviousPagination(previousData)
  }

  useEffect(() => {
    //For Showing All Data
    const fetchData = async () => {
      let userData = await fetch("https://api-admin.indgiants.in/api/callers");
      userData = await userData.json();
      let limitedData = userData.data;
      setallData(limitedData)

      // Set Next Page URL, It send the link to Button
      let nextData = userData.next_page_url;
      setnextPagination(nextData)

      // Set Prev Page URL, It send the link to Button
      let previousData = userData.prev_page_url;
      setpreviousPagination(previousData)
    }

    const timer = setTimeout(() => {
      fetchData();
      setchooseDisplay('spinnerNone')
    }, 1000);

    return () => clearTimeout(timer);

  }, [caller_email])
  
  // Next Page Navigation Function
  const [nextPagination, setnextPagination] = useState([])
  // Getting NextPageLink from Button
  const nextPage = async(nextPageLink) => {
    let getNextPageData = await fetch(nextPageLink)
    getNextPageData = await getNextPageData.json();
    let sendNextPageData = getNextPageData.data;

    // Sending Data to allData state, so it will show next data
    setallData(sendNextPageData)
    
    let againNextPageURL = getNextPageData.next_page_url;
    // If Next Page Data is null, Next Button will be disabled
    // And Set Next Page Button Link Again
    setnextPagination(againNextPageURL)

    let prevPageURL = getNextPageData.prev_page_url;
    // If Previous Page Data is null, Prev Button will be disabled
    // And Set Previous Page Button Link Again
    setpreviousPagination(prevPageURL)
  }

  // Previous Page Navigation Function
  const [previousPagination, setpreviousPagination] = useState([])  
  const previousPage = async(prevPageLink) => {
    let getPageData = await fetch(prevPageLink)
    getPageData = await getPageData.json();
    let sendPrevPageData = getPageData.data;

    // Sending Datewise Data to allData state, so it will show next data
    setallData(sendPrevPageData)
    
    let againPrevPageURL = getPageData.prev_page_url;
    // If Previous Page Data null, Prev Button will be disabled
    // And Set Previous Page Button Link Again
    setpreviousPagination(againPrevPageURL)

    let nextPageURL = getPageData.next_page_url;
    // If Next Page Data null, Next Button will be disabled
    // And Set Next Page Button Link Again
    setnextPagination(nextPageURL)
  }

  // For Showing Specific Data in Model box
  // const [speificData, setspeificData] = useState([])
  // const showSpecificData = async($id) => {
  //   let getspecificData = await fetch("https://api-admin.indgiants.in/api/specific-caller/" + $id)
  //   getspecificData = await getspecificData.json();
  //   setspeificData(getspecificData)
  //   setVisibleXL(!visibleXL)
  // }

  // // ModelBox Visibility
  // const [visibleXL, setVisibleXL] = useState(false)

  // console.info(allData)

  const setCallerID = (id, email) => {
    localStorage.setItem('caller_id', id)
    localStorage.setItem('caller_email', email)
  }

  // Activate Caller
  const ActivateformData = new FormData();
  ActivateformData.append('status', "activated")

  const callerStatusActivate = async(e, caller) => {
    let url = await fetch("https://api-admin.indgiants.in/api/caller-status-update/" + caller, {
      method: "POST", 
      body: ActivateformData
    })
    .then((res) => {
      return
    })
    .catch((error) => {
      console.info(error)
    })
  }

  // Deactivate Caller
  const DeactivateformData = new FormData();
  DeactivateformData.append('status', "deactivated")

  const callerStatusDeactivate = async(caller) => {
    let url = await fetch("https://api-admin.indgiants.in/api/caller-status-update/" + caller, {
      method: "POST", 
      body: DeactivateformData
    })
    .then((res) => {
      console.info(res)
    })
    .catch((error) => {
      console.info(error)
    })
  }

  const [Loading, setLoading] = useState(true)

  useEffect(() => {
    if (allData.length != 0) {
      setLoading(false)
    }
  })
  
  return (
    <>
      {/* Spinner */}
      {/* <CSpinner className="mb-4" id={chooseDisplay} /> */}
      {/* All Data */}
      <CRow>
        <CCol xs={12}>
          {
            Loading ?
            <CSpinner style={{ margin: "20px" }} />
            :
            <CCard className="mb-4" style={{ width: "95%", margin: "20px" }}>
            <CCardHeader style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <div>
                <strong>All Callers </strong> <small>Data Table</small>
              </div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CButton color="dark" size="sm" onClick={ getData }>Show All</CButton>
              </div>
            </CCardHeader>
              <CCardBody>
                <div>
                  <CTable bordered>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col">#</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Mobile</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                        <CTableHeaderCell scope="col">More</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      <React.Suspense fallback={loading}>
                          {
                          allData.map((data) =>
                              <CTableRow key={data.id}>
                              <CTableHeaderCell scope="row">{data.id}</CTableHeaderCell>
                              <CTableDataCell>{data.first_name} {data.last_name}</CTableDataCell>
                              <CTableDataCell>{data.mobile}</CTableDataCell>
                              <CTableDataCell>{data.email}</CTableDataCell>
                              <CTableDataCell>
                                {
                                  data.status === 'deactivated' || data.status === '' ?  
                                  <CFormSwitch size="lg" label="Deactivated" onChange={(e) => callerStatusActivate(e, data.id)} id="deactivatedButton"/>
                                  :                                
                                  <CFormSwitch size="lg" label="Activated" onChange={() => callerStatusDeactivate(data.id)} defaultChecked/>
                                }
                              </CTableDataCell>
                              <CTableDataCell>
                                  <Link to='caller-data' state={data.email}>
                                    <CButton color="success" variant="outline" onClick={() => setCallerID(data.id, data.email)}>More Info</CButton>
                                  </Link>
                              </CTableDataCell>
                              </CTableRow>
                            )
                          }
                      </React.Suspense>
                    </CTableBody>
                  </CTable>
                </div>
                <CPagination align="end" aria-label="Page navigation example">
                  {/* Prev Page Button */}
                  {
                    // If Previous Page URL is null
                    previousPagination === null ?
                    <CPaginationItem disabled>Previous</CPaginationItem> :
                    <CPaginationItem onClick={() => previousPage(previousPagination)} style={{ cursor: "pointer" }}>Previous</CPaginationItem>
                  }
                  {/* Next Page Button */}
                  {
                    // If Next  Page URL is null
                    nextPagination === null ?
                    <CPaginationItem onClick={() => nextPage(nextPagination)} disabled>Next</CPaginationItem> : 
                    <CPaginationItem onClick={() => nextPage(nextPagination)} style={{ cursor: "pointer" }}>Next</CPaginationItem>
                  }
                  {/* <CPaginationItem onClick={() => nextPage(Pagination)} id={Pagination}>Next</CPaginationItem> */}
                </CPagination>
              </CCardBody>
            </CCard>
          }
        </CCol>
      </CRow>
    </>
  )
}

export default AllCallers