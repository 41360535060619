import { useParams } from "react-router-dom";
import React, { useState, useEffect, useContext, useMemo } from 'react'
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CTable,
    CTableBody,
    // CTableCaption,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CModalFooter,
    CPagination,
    CPaginationItem,
    CSpinner
} from '@coreui/react';
// Context Provider for Getting Logged In User Email ID
import { userContext } from '../../components/Routing';

const CallData = () => {

    const user_Details = useContext(userContext)

    const params = useParams();
    const [appNamefromParams, setAppNamefromParams] = useState("")

    useEffect(() => {
        setAppNamefromParams(params)
    }, [params])

    // const caller_email = user_Details.email;

    const [caller_id, setCallerId] = useState('')
    useEffect(() => {
        if (user_Details.id != undefined) {
            setCallerId(user_Details.id)
        }
    }, [user_Details])

    const [allData, setallData] = useState([])
    const [Loading, setLoading] = useState(false)
    const [loadSpinner, setloadSpinner] = useState("loadingSpinner")

    //For Showing All Data
    const getData = async () => {
        let callData = await fetch(`https://api.indgiants.in/api/show-caller-data/${callerid}`);
        // console.info(callData)
        callData = await callData.json();
        let limitedData = callData.data;
        setallData(limitedData)

        // Set Next Page URL, It send the link to Button
        let nextData = callData.next_page_url;
        setnextPagination(nextData)

        // Set Prev Page URL, It send the link to Button
        let previousData = callData.prev_page_url;
        setpreviousPagination(previousData)
    }

    const callerid = localStorage.getItem("caller_id")

    useEffect(() => {
        //For Showing All Data
        const fetchData = () => {
            fetch(`https://api.indgiants.in/api/show-caller-data/${callerid}`)
                .then(res => res.json())
                .then(res => {
                    let callData = res;
                    setallData(res.data)
                    // Set Next Page URL, It send the link to Button
                    let nextData = callData.next_page_url;
                    setnextPagination(nextData)

                    // Set Prev Page URL, It send the link to Button
                    let previousData = callData.prev_page_url;
                    setpreviousPagination(previousData)
                    setloadSpinner("spinnerNone")

                    if (callData.data[0] != undefined) {
                        setLoading(true)
                    }
                    if (callData.data.length === 0) {
                        console.info("No Data")
                        setLoading(false)
                    }
                })
                .catch(error => console.error("ERROR"))
        }
        fetchData();
        getData();
    }, [appNamefromParams])

    // Next Page Navigation Function
    const [nextPagination, setnextPagination] = useState([])
    // Getting NextPageLink from Button
    const nextPage = async (nextPageLink) => {
        let getNextPageData = await fetch(nextPageLink)
        getNextPageData = await getNextPageData.json();
        let sendNextPageData = getNextPageData.data;

        // Sending Data to allData state, so it will show next data
        setallData(sendNextPageData)

        let againNextPageURL = getNextPageData.next_page_url;
        // If Next Page Data is null, Next Button will be disabled
        // And Set Next Page Button Link Again
        setnextPagination(againNextPageURL)

        let prevPageURL = getNextPageData.prev_page_url;
        // If Previous Page Data is null, Prev Button will be disabled
        // And Set Previous Page Button Link Again
        setpreviousPagination(prevPageURL)
    }

    // Previous Page Navigation Function
    const [previousPagination, setpreviousPagination] = useState([])
    const previousPage = async (prevPageLink) => {
        let getPageData = await fetch(prevPageLink)
        getPageData = await getPageData.json();
        let sendPrevPageData = getPageData.data;

        // Sending Datewise Data to allData state, so it will show next data
        setallData(sendPrevPageData)

        let againPrevPageURL = getPageData.prev_page_url;
        // If Previous Page Data null, Prev Button will be disabled
        // And Set Previous Page Button Link Again
        setpreviousPagination(againPrevPageURL)

        let nextPageURL = getPageData.next_page_url;
        // If Next Page Data null, Next Button will be disabled
        // And Set Next Page Button Link Again
        setnextPagination(nextPageURL)
    }

    const startDate = (e) => console.log(e.value);
    const endDate = (e) => console.log(e);
    // For Showing Datewise Data
    const showDateWiseData = async () => {
        const start_date = document.getElementById("startDate").value;
        const end_date = document.getElementById("endDate").value;
        let getdateWiseData = await fetch(`https://api.indgiants.in/api/show-all-data/${callerid}/` + start_date + "/" + end_date)
        getdateWiseData = await getdateWiseData.json();


        // Sending Datewise Data to allData state, so it will show datewise data instead of all data
        let limitedData = getdateWiseData.data;
        setallData(limitedData)

        // Set Next Page Button Link Again
        let nextPageURL = getdateWiseData.next_page_url;
        setnextPagination(nextPageURL)

        // Set Next Page Button Link Again
        let prevPageURL = getdateWiseData.prev_page_url;
        setpreviousPagination(prevPageURL)
    }

    // For Showing Specific Data in Model box
    const [speificData, setspeificData] = useState([])
    const showSpecificData = async (id) => {
        let getspecificData = await fetch(`https://api.indgiants.in/api/show-data/${id}`)
        getspecificData = await getspecificData.json();
        setspeificData(getspecificData)
        setVisibleXL(!visibleXL)
    }

    // ModelBox Visibility
    const [visibleXL, setVisibleXL] = useState(false)

    const [noData, setnoData] = useState("")

    useEffect(() => {
        setTimeout(() => {
            setnoData("No Data Found")
        }, 5000);
    }, [])


    return (
        <>
            {/* Pop-Up */}
            <CModal size="xl" visible={visibleXL} onClose={() => setVisibleXL(false)}>
                <CModalHeader onClose={() => setVisibleXL(false)}>
                    <CModalTitle>Caller Data</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <strong>Caller : </strong>{speificData.caller_id}
                    <br />
                    <strong>Mobile : </strong>{speificData.mobile_number}
                    <br />
                    <strong>Institute : </strong>{speificData.school_name}
                    <br />
                    <strong>Institute Address : </strong>{speificData.school_address}
                    <br />
                    <strong>Call Attempts : </strong>{speificData.call_attempts}
                    <br />
                    <strong>Call Status : </strong>{speificData.call_status}
                    <br />
                    <strong>Call Summary : </strong>{speificData.call_summary}
                    <br />
                    <strong>In-Charge Name : </strong>{speificData.incharge_name}
                    <br />
                    <strong>In-Charge Designation : </strong>{speificData.incharge_designation}
                    <br />
                    <strong>Current Teaching Method : </strong>{speificData.current_teaching_method}
                    <br />
                    <strong>Other Platform : </strong>{speificData.other_platform}
                    <br />
                    <strong>In-Charge Response : </strong>{speificData.incharge_response}
                    <br />
                    <strong>Caller Opinion on In-Charge : </strong>{speificData.tele_caller_opinion}
                </CModalBody>
                <CModalFooter>
                    <CButton color="warning" onClick={() => setVisibleXL(false)}>
                        Close
                    </CButton>
                </CModalFooter>
            </CModal>

            {/* All Data */}
            <CRow className="centerColumn mb-4">
                <CCol xs={12}>
                    {
                        Loading ?
                            <CCard className="mb-4" style={{ width: "95%", margin: "20px" }}>
                                <CCardHeader style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <strong>{appNamefromParams.app} </strong> <small>Data Table</small>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <input type="date" id="startDate" name="start_date" onChange={(e) => startDate(e)} />
                                        <input type="date" id="endDate" name="start_date" onChange={(e) => endDate(e)} style={{ marginLeft: '10px' }} />
                                        <CButton color="dark" size="sm" onClick={showDateWiseData} style={{ marginLeft: '10px' }}>Show Data</CButton>
                                    </div>
                                </CCardHeader>
                                <CCardBody>
                                    <div className="table-Div">
                                        <CTable bordered>
                                            <CTableHead>
                                                <CTableRow style={{ fontSize: "14px" }}>
                                                    {/* <CTableHeaderCell scope="col">#</CTableHeaderCell> */}
                                                    <CTableHeaderCell scope="col">Mobile</CTableHeaderCell>
                                                    <CTableHeaderCell scope="col">Call Status</CTableHeaderCell>
                                                    <CTableHeaderCell scope="col">School</CTableHeaderCell>
                                                    <CTableHeaderCell scope="col">Address</CTableHeaderCell>
                                                    <CTableHeaderCell scope="col">Teaching Method</CTableHeaderCell>
                                                    <CTableHeaderCell scope="col">Other Platform</CTableHeaderCell>
                                                    <CTableHeaderCell scope="col">Response</CTableHeaderCell>
                                                    <CTableHeaderCell scope="col" style={{ width: "120px" }}>More</CTableHeaderCell>
                                                </CTableRow>
                                            </CTableHead>
                                            <CTableBody>
                                                {
                                                    allData.map((data) =>
                                                        <CTableRow key={data.id} style={{ fontSize: "14px" }}>
                                                            {/* <CTableHeaderCell scope="row"></CTableHeaderCell> */}
                                                            <CTableDataCell>{data.mobile_number}</CTableDataCell>
                                                            <CTableDataCell>{data.call_status}</CTableDataCell>
                                                            <CTableDataCell style={{ width: "15%" }}>{data.school_name}</CTableDataCell>
                                                            <CTableDataCell style={{ width: "20%" }}>{data.school_address}</CTableDataCell>
                                                            <CTableDataCell>{data.current_teaching_method}</CTableDataCell>
                                                            <CTableDataCell>{data.other_platform}</CTableDataCell>
                                                            <CTableDataCell>{data.incharge_response}</CTableDataCell>
                                                            <CTableDataCell><CButton color="success" variant="outline" onClick={() => showSpecificData(data.id)} style={{ fontSize: "14px" }}>More Info</CButton></CTableDataCell>
                                                        </CTableRow>
                                                    )
                                                }
                                            </CTableBody>
                                        </CTable>
                                    </div>
                                    {/* <CSpinner id={loadSpinner} /> */}
                                    <CPagination align="end" aria-label="Page navigation example">
                                        {/* Prev Page Button */}
                                        {
                                            // If Previous Page URL is null
                                            previousPagination === null ?
                                                <CPaginationItem disabled>Previous</CPaginationItem> :
                                                <CPaginationItem onClick={() => previousPage(previousPagination)} style={{ cursor: "pointer" }}>Previous</CPaginationItem>
                                        }
                                        {/* Next Page Button */}
                                        {
                                            // If Next  Page URL is null
                                            nextPagination === null ?
                                                <CPaginationItem onClick={() => nextPage(nextPagination)} disabled>Next</CPaginationItem> :
                                                <CPaginationItem onClick={() => nextPage(nextPagination)} style={{ cursor: "pointer" }}>Next</CPaginationItem>
                                        }
                                        {/* <CPaginationItem onClick={() => nextPage(Pagination)} id={Pagination}>Next</CPaginationItem> */}
                                    </CPagination>
                                </CCardBody>
                            </CCard>
                            :
                            <>
                                {
                                    allData.length === 0 ?
                                        <h1>{noData}</h1>
                                        :
                                        <>
                                            <CSpinner />
                                        </>
                                }
                            </>
                    }
                </CCol>
            </CRow>
        </>
    )
}


export default CallData;