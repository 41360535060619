import React, { useState, useEffect, useRef, useContext } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CSpinner,
  CForm,
  CFormLabel,
  CFormInput,
  CToast,
  CToastBody,
  CToastHeader,
  CToaster,
  CToastClose,
  CCloseButton 
} from '@coreui/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Context Provider for Getting Logged In User Email ID
import { userContext } from '../components/Routing';

toast.configure();

const Profile = () => {

  const user_Details = useContext(userContext)

  const [GetCallerData, setGetCallerData] = useState([])
  
  const callerFirstName = GetCallerData.first_name;
  const callerSecondname = GetCallerData.last_name;
  const callerMobile = GetCallerData.mobile;
  const callerEmail = GetCallerData.email;
  const callerId = GetCallerData.id;

  const gettingCallerDetails = () => {
    if(user_Details.first_name !== undefined){
      setGetCallerData(user_Details)
      setLoading(false)
    }
  }

  useEffect(() => {
    gettingCallerDetails();
  }, [user_Details])

// const callerFirstName = "Sai";
// const callerSecondname = "Krishna";
// const callerMobile = 8974567898;
// const callerEmail = "sayi1996m@gmail.com";
// const callerId = 50;

  const [Loading, setLoading] = useState(true)
  // ModelBox Visibility
  const [visibleXL, setVisibleXL] = useState(false)

  // For Showing Specific Data in Model box
  const [userData, setUserData] = useState([])
  let TokenKey = localStorage.getItem('session_token')

  const openModel = async() => {
    let getspecificData = await fetch("https://api.indgiants.in/api/user", {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${TokenKey}`
      },
    })
    .then(response => response.json())
    .then(data => {
      setUserData(data)
    })
    .catch((error) => {
      return
    })
    setVisibleXL(!visibleXL)
  }

  const sendMessage = async(e) => {
    e.preventDefault()

    const newPassword = document.getElementById("new-password").value;
    const confirmPassword = document.getElementById("confirm-password").value;

    const formData = new FormData();

    formData.append('user_id', callerId)
    formData.append('password', newPassword)
    formData.append('password_confirmation', confirmPassword)

    let getspecificData = await fetch("https://api.indgiants.in/api/user/change-password", {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${TokenKey}`
      },
      body: formData
    })
    .then((res) => {
      if(res.status == 204 || res.status == 200){
        setVisibleXL(false)
        toast.success("Password has been changed successfully.");
      }
    })
    .catch((error) => {
      console.error(error.message)
    })
  }

//   Tost
const [toast, addToast] = useState(0)
const toaster = useRef()

  return (
    <>
      {/* Pop-Up */}
      <CToaster ref={toaster} push={toast} placement="top-end" />
      <CModal size="xl" visible={visibleXL} onClose={() => setVisibleXL(false)}>
        <CModalHeader onClose={() => setVisibleXL(false)}>
          <CModalTitle>Please Change Password</CModalTitle>
        </CModalHeader>
          <CModalBody>
            <CForm onSubmit={(e) => sendMessage(e)}>
              <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput1">New Password</CFormLabel>
                <CFormInput type="password" id="new-password" placeholder='New Password' required/>
              </div>

              <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput1">Confirm Password</CFormLabel>
                <CFormInput type="password" id="confirm-password" placeholder='Confirm Password' required/>
              </div>

              <CCol xs={12}>
                <div className="d-grid gap-2 col-2">
                  <CButton type="submit">Save</CButton>
                </div>
              </CCol>

            </CForm>
          </CModalBody>
        <CModalFooter>
          <CButton color="warning" onClick={() => setVisibleXL(false)}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>

      {/* All Data */}
      <CRow className='centerColumn mt-4 mb-4'>
        <CCol xs={11}>
          {
            Loading ?
            <CSpinner />
            :
            <CCard className="mb-4" style={{ textAlign: "left" }}>
                {/* <CButton onClick={() => addToast(exampleToast)}>Send a toast</CButton> */}
            <CCardHeader style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <strong>Hello </strong>{ callerFirstName }
              </div>
            </CCardHeader>
              <CCardBody>
                <div>
                  <CTable bordered>
                    <CTableHead>
                      <CTableRow>
                          <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                          <CTableDataCell>{ callerFirstName + " " + callerSecondname}</CTableDataCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      <CTableRow>
                          <CTableHeaderCell scope="row">Mobile</CTableHeaderCell>
                          <CTableDataCell>{ callerMobile }</CTableDataCell>
                      </CTableRow>
                      <CTableRow>
                          <CTableHeaderCell scope="row">Email</CTableHeaderCell>
                          <CTableDataCell>{ callerEmail }</CTableDataCell>
                      </CTableRow>
                    </CTableBody>
                  </CTable>
                </div>
              <CButton color="warning" onClick={() => openModel()}>Change Password</CButton>
              </CCardBody>
            </CCard>
          }
        </CCol>
      </CRow>
    </>
  )
}

export default Profile;