import React, { useState, useEffect } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable,
  CTableBody,
  // CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CPagination,
  CPaginationItem,
  CFormSwitch,
  CSpinner,
  CForm,
  CFormLabel,
  CFormInput,
  CFormTextarea
} from '@coreui/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )

const ActivatedCallers = () => {

  const caller_email = "sayi1996m@gmail.com";
  // const [Email, setEmail] = useState('')
  // setEmail(caller)
  // Getting Data from:
  // Onloading: getData() function
  // NextPage: nextPage() function
  // Previous Page: previousPage() function
  // Datewise: showDatewiseData() function
  const [allData, setallData] = useState([])

  const [chooseDisplay, setchooseDisplay] = useState('spinnerDisplay')

  //For Showing All Data
  const getData = async () => {
    let userData = await fetch("https://api-admin.indgiants.in/api/activated-callers");
    userData = await userData.json();
    let limitedData = userData.data;
    setallData(limitedData)

    // Set Next Page URL, It send the link to Button
    let nextData = userData.next_page_url;
    setnextPagination(nextData)

    // Set Prev Page URL, It send the link to Button
    let previousData = userData.prev_page_url;
    setpreviousPagination(previousData)
  }

  useEffect(() => {
    //For Showing All Data
    const fetchData = async () => {
      let userData = await fetch("https://api-admin.indgiants.in/api/activated-callers");
      userData = await userData.json();
      let limitedData = userData.data;
      setallData(limitedData)

      // Set Next Page URL, It send the link to Button
      let nextData = userData.next_page_url;
      setnextPagination(nextData)

      // Set Prev Page URL, It send the link to Button
      let previousData = userData.prev_page_url;
      setpreviousPagination(previousData)
    }

    const timer = setTimeout(() => {
      fetchData();
      setchooseDisplay('spinnerNone')
    }, 1000);

    return () => clearTimeout(timer);

  }, [caller_email])
  
  // Next Page Navigation Function
  const [nextPagination, setnextPagination] = useState([])
  // Getting NextPageLink from Button
  const nextPage = async(nextPageLink) => {
    let getNextPageData = await fetch(nextPageLink)
    getNextPageData = await getNextPageData.json();
    let sendNextPageData = getNextPageData.data;

    // Sending Data to allData state, so it will show next data
    setallData(sendNextPageData)
    
    let againNextPageURL = getNextPageData.next_page_url;
    // If Next Page Data is null, Next Button will be disabled
    // And Set Next Page Button Link Again
    setnextPagination(againNextPageURL)

    let prevPageURL = getNextPageData.prev_page_url;
    // If Previous Page Data is null, Prev Button will be disabled
    // And Set Previous Page Button Link Again
    setpreviousPagination(prevPageURL)
  }

  // Previous Page Navigation Function
  const [previousPagination, setpreviousPagination] = useState([])  
  const previousPage = async(prevPageLink) => {
    let getPageData = await fetch(prevPageLink)
    getPageData = await getPageData.json();
    let sendPrevPageData = getPageData.data;

    // Sending Datewise Data to allData state, so it will show next data
    setallData(sendPrevPageData)
    
    let againPrevPageURL = getPageData.prev_page_url;
    // If Previous Page Data null, Prev Button will be disabled
    // And Set Previous Page Button Link Again
    setpreviousPagination(againPrevPageURL)

    let nextPageURL = getPageData.next_page_url;
    // If Next Page Data null, Next Button will be disabled
    // And Set Next Page Button Link Again
    setnextPagination(nextPageURL)
  }

  // For Showing Specific Data in Model box
  const [specificData, setspeificData] = useState([])
  const showSpecificData = async($id) => {
    let getspecificData = await fetch("https://api-admin.indgiants.in/api/specific-caller/" + $id)
    let userData = await getspecificData.json();
    getspecificData = userData[0];
    setspeificData(getspecificData)
    setVisibleXL(!visibleXL)
  }

  // ModelBox Visibility
  const [visibleXL, setVisibleXL] = useState(false)

  // Deactivate Caller
  const DeactivateformData = new FormData();
  DeactivateformData.append('status', "deactivated")

  const callerStatusDeactivate = async(caller) => {
    let url = await fetch("https://api-admin.indgiants.in/api/caller-status-update/" + caller, {
      method: "POST", 
      body: DeactivateformData
    })
    .then((res) => {
      console.info(res)
    })
    .catch((error) => {
      console.info(error)
    })
  }

  const [Loading, setLoading] = useState(true)

  useEffect(() => {
    if (allData.length != 0) {
      setLoading(false)
    }
  })

  const sendMessage = async(e) => {
    e.preventDefault()

    const caller_email = specificData.email;
    const message = document.getElementById("message").value;

    const formData = new FormData();

    formData.append('caller_email', caller_email)
    formData.append('message', message)

      let url = await fetch("https://api-admin.indgiants.in/api/send-mail", {
        method: "POST", 
        body: formData
      })
      .then((res) => {
        if(res.status === 200 || res.status === 201){
          toast.success("Message has been sent successfully.");
        }
      })
      .catch((error) => {
        console.error(error.message)
      })
  }
  
  return (
    <>
      {/* Pop-Up */}
      <CModal size="xl" visible={visibleXL} onClose={() => setVisibleXL(false)}>
        <CModalHeader onClose={() => setVisibleXL(false)}>
          <CModalTitle>Send Message</CModalTitle>
        </CModalHeader>
          <CModalBody>
            <CForm onSubmit={(e) => sendMessage(e)}>
              <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlInput1">Email address</CFormLabel>
                <CFormInput type="email" id="exampleFormControlInput1" value={specificData.email}/>
              </div>
              <div className="mb-3">
                <CFormLabel htmlFor="exampleFormControlTextarea1">Message</CFormLabel>
                <CFormTextarea id="message" rows="3"></CFormTextarea>
              </div>
              <CCol xs={12}>
                <CButton type="submit">Send</CButton>
              </CCol>
            </CForm>
          </CModalBody>
        <CModalFooter>
          <CButton color="warning" onClick={() => setVisibleXL(false)}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>


      {/* <CSpinner  className="mb-4" id={chooseDisplay} /> */}
      {/* All Data */}
      <CRow>
        <CCol xs={12}>
          {
            Loading ?
            <CSpinner style={{ margin: "20px" }} />
            :
            <CCard className="mb-4" style={{ width: "95%", margin: "20px" }}>
            <CCardHeader style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <div>
                <strong>Activated Callers </strong> <small>Data Table</small>
              </div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CButton color="dark" size="sm" onClick={ getData }>Show All</CButton>
              </div>
            </CCardHeader>
              <CCardBody>
                <div>
                  <CTable bordered align="middle" responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col">#</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Mobile</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                        <CTableHeaderCell scope="col">More</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                          {
                            allData.map((data) =>
                                <CTableRow key={data.id}>
                                <CTableHeaderCell scope="row">{data.id}</CTableHeaderCell>
                                <CTableDataCell>{data.first_name} {data.last_name}</CTableDataCell>
                                <CTableDataCell>{data.mobile}</CTableDataCell>
                                <CTableDataCell>{data.email}</CTableDataCell>
                                <CTableDataCell>
                                    <CFormSwitch size="lg" label="Activated" onChange={() => callerStatusDeactivate(data.id)} defaultChecked/>
                                </CTableDataCell>
                                <CTableDataCell>
                                    <CButton color="success" variant="outline" onClick={() => showSpecificData(data.id)}>Send Message</CButton>
                                </CTableDataCell>
                                </CTableRow>
                            )
                          }
                    </CTableBody>
                  </CTable>
                </div>
                <CPagination align="end" aria-label="Page navigation example">
                  {/* Prev Page Button */}
                  {
                    // If Previous Page URL is null
                    previousPagination === null ?
                    <CPaginationItem disabled>Previous</CPaginationItem> :
                    <CPaginationItem onClick={() => previousPage(previousPagination)} style={{ cursor: "pointer" }}>Previous</CPaginationItem>
                  }
                  {/* Next Page Button */}
                  {
                    // If Next  Page URL is null
                    nextPagination === null ?
                    <CPaginationItem onClick={() => nextPage(nextPagination)} disabled>Next</CPaginationItem> : 
                    <CPaginationItem onClick={() => nextPage(nextPagination)} style={{ cursor: "pointer" }}>Next</CPaginationItem>
                  }
                  {/* <CPaginationItem onClick={() => nextPage(Pagination)} id={Pagination}>Next</CPaginationItem> */}
                </CPagination>
              </CCardBody>
            </CCard>
          }
        </CCol>
      </CRow>
    </>
  )
}

export default ActivatedCallers