import {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { 
    CContainer,
    CNavbar,
    CHeaderToggler,
    CSidebarBrand,
    CSidebarNav,
    CBadge,
    CNavGroup,
    CSidebarToggler,
    CNavItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {cilMenu, 
        cilDescription,
        cilNoteAdd
    } from '@coreui/icons'
import Sidebar from "./Sidebar";

const Header = () => {
    // const [visible, setVisible] = useState(false)
    const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)

    return (
        <>
            <CNavbar colorScheme="light" className="bg-light">
                <CContainer fluid>
                <CHeaderToggler
                    className="ps-1"
                    onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
                    >
                    {/* <CIcon icon={cilMenu} size="xxl" 
                        aria-controls="offcanvasNavbar"
                        aria-label="Toggle navigation"
                        onClick={() => setVisible(!visible)}
                    /> */}
                </CHeaderToggler>
                </CContainer>
            </CNavbar>
        </>
    )
}

export default Header;