import React, { useState, useEffect } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable,
  CTableBody,
  // CTableCaption,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CForm,
  CFormLabel,
  CFormInput,
  CFormTextarea,
  CSpinner,
  CFormSwitch
} from '@coreui/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CallerApps from "./CallerApps";
// import { forEach } from 'core-js/core/array';

// Context Provider for Getting Logged In User Email ID
// import { loggedInUserData } from 'src/components/AppContent';

toast.configure();

const CallerData = () => {

//   const caller_email = "third@mail.com";
  // const [Email, setEmail] = useState('')
  // setEmail(caller)
  // Getting Data from:
  // Onloading: getData() function
  // NextPage: nextPage() function
  // Previous Page: previousPage() function
  // Datewise: showDatewiseData() function
  const [allData, setallData] = useState([])


  const callerID = localStorage.getItem('caller_id')
  
  //For Showing All Data
  const getData = async () => {
    let callData = await fetch("https://api-admin.indgiants.in/api/caller-data/" + callerID);
    callData = await callData.json();
    let limitedData = callData.data;
    setallData(limitedData)

    // Set Next Page URL, It send the link to Button
    let nextData = callData.next_page_url;
    setnextPagination(nextData)

    // Set Prev Page URL, It send the link to Button
    let previousData = callData.prev_page_url;
    setpreviousPagination(previousData)
  }


  // For Showing Specific Data in Model box
  const [callerData, setcallerData] = useState([])

  // console.info(callerData)

  const getCallerData = async () => {
    let callerDataURL = await fetch("https://api-admin.indgiants.in/api/specific-caller/" + callerID)
    callerDataURL = await callerDataURL.json();
    callerDataURL = callerDataURL[0];
    setcallerData(callerDataURL)
  }

  useEffect(() => {
    //For Showing All Data
    const fetchData = async () => {
      let callData = await fetch("https://api-admin.indgiants.in/api/caller-data/" + callerID);
      callData = await callData.json();
      let limitedData = callData.data;
      setallData(limitedData)

      // Set Next Page URL, It send the link to Button
      let nextData = callData.next_page_url;
      setnextPagination(nextData)

      // Set Prev Page URL, It send the link to Button
      let previousData = callData.prev_page_url;
      setpreviousPagination(previousData)
    }

    const timer = setTimeout(() => {
      fetchData();
    }, 1000);

    getCallerData()

    return () => clearTimeout(timer);

  }, [callerID])

  // Next Page Navigation Function
  const [nextPagination, setnextPagination] = useState([])
  // Getting NextPageLink from Button
  const nextPage = async (nextPageLink) => {
    let getNextPageData = await fetch(nextPageLink)
    getNextPageData = await getNextPageData.json();
    let sendNextPageData = getNextPageData.data;

    // Sending Data to allData state, so it will show next data
    setallData(sendNextPageData)

    let againNextPageURL = getNextPageData.next_page_url;
    // If Next Page Data is null, Next Button will be disabled
    // And Set Next Page Button Link Again
    setnextPagination(againNextPageURL)

    let prevPageURL = getNextPageData.prev_page_url;
    // If Previous Page Data is null, Prev Button will be disabled
    // And Set Previous Page Button Link Again
    setpreviousPagination(prevPageURL)
  }

  // Previous Page Navigation Function
  const [previousPagination, setpreviousPagination] = useState([])
  const previousPage = async (prevPageLink) => {
    let getPageData = await fetch(prevPageLink)
    getPageData = await getPageData.json();
    let sendPrevPageData = getPageData.data;

    // Sending Datewise Data to allData state, so it will show next data
    setallData(sendPrevPageData)

    let againPrevPageURL = getPageData.prev_page_url;
    // If Previous Page Data null, Prev Button will be disabled
    // And Set Previous Page Button Link Again
    setpreviousPagination(againPrevPageURL)

    let nextPageURL = getPageData.next_page_url;
    // If Next Page Data null, Next Button will be disabled
    // And Set Next Page Button Link Again
    setnextPagination(nextPageURL)
  }

  // For Showing Datewise Data
  const showDateWiseData = async () => {
    const particularDate = document.getElementById("dateWise").value;
    let getdateWiseData = await fetch("https://api-admin.indgiants.in/api/show-learz-data/email/" + callerID + "/date/" + particularDate)
    getdateWiseData = await getdateWiseData.json();


    // Sending Datewise Data to allData state, so it will show datewise data instead of all data
    let limitedData = getdateWiseData.data;
    setallData(limitedData)

    // Set Next Page Button Link Again
    let nextPageURL = getdateWiseData.next_page_url;
    setnextPagination(nextPageURL)

    // Set Next Page Button Link Again
    let prevPageURL = getdateWiseData.prev_page_url;
    setpreviousPagination(prevPageURL)

  }

  // For Showing Specific Data in Model box
  const [specificData, setspecificData] = useState([])
  const showSpecificData = async ($app, $id) => {
    let app = $app.toLowerCase()
    let getspecificData = await fetch("https://api-admin.indgiants.in/api/call-data/" + app + "/" + $id)
    getspecificData = await getspecificData.json();
    setspecificData(getspecificData)
    setVisibleXL(!visibleXL)
  }

  // ModelBox Visibility
  const [visibleXL, setVisibleXL] = useState(false)

  const [Loading, setLoading] = useState(true)
  useEffect(() => {
    if (callerData.id != undefined) {
      setLoading(false)
    }
  }, [callerData])

  const sendMessage = async (e) => {
    e.preventDefault()

    const caller_email = callerData.email;
    const message = document.getElementById("message").value;

    const formData = new FormData();

    formData.append('caller_email', caller_email)
    formData.append('message', message)

    // alert(callerData.email)

    let url = await fetch("https://api-admin.indgiants.in/api/send-mail", {
      method: "POST",
      body: formData
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success("Message has been sent successfully.");
        }
      })
      .catch((error) => {
        console.error(error.message)
      })
  }

  const [isAPP, setIsAPP] = useState([])
  const [caller_apps, setCaller_Apps] = useState([])
  const [totalApps, setTotalApps] = useState([])

  const getApps = async (callerID) => {
    const userData = new FormData();
    userData.append("caller_id", callerID)
    let url = await fetch("https://api-admin.indgiants.in/api/caller-apps", {
      method: "POST",
      body: userData
    })
      .then((res) => res.json())
      .then((res) => {
        if (res != undefined) {
          setCaller_Apps(res)
          res.map((data) => {
            console.log(data.caller_app)
            setTotalApps(data.caller_app)
            const appData = Object.values(data)
            // console.info(appData.slice(1))
          })
        }
      }
      )
      .catch((err) => null)
  }

  useEffect(() => {
    let callerApps = callerData.apps
    if (callerApps != undefined) {
      callerApps = callerApps.split(", ");
      setIsAPP(callerApps)
    }
    getApps(callerData.id);
  }, [callerData])


  return (
    <>
      <CRow>
        {
          Loading ?
            <CSpinner color="dark" style={{ margin: "20px" }}/>
            :
            <CCol xs={12} style={{ textAlign: "left", width: "100%" }}>
              <CCard className="mb-4" style={{ width: "95%", margin: "20px" }}>
                <CCardHeader style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <strong>Hello </strong>{ }
                  </div>
                </CCardHeader>
                <CCardBody>
                  <div>
                    <CTable bordered>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell scope="col">Full Name</CTableHeaderCell>
                          <CTableDataCell>{callerData.first_name + " " + callerData.last_name}</CTableDataCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        <CTableRow>
                          <CTableHeaderCell scope="row">Mobile</CTableHeaderCell>
                          <CTableDataCell>{callerData.mobile}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                          <CTableHeaderCell scope="row">Email</CTableHeaderCell>
                          <CTableDataCell>{callerData.email}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                          <CTableHeaderCell scope="row">Designation</CTableHeaderCell>
                          <CTableDataCell>{callerData.designation}</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                          <CTableHeaderCell scope="row">Status</CTableHeaderCell>
                          <CTableDataCell style={{ textTransform: "capitalize" }}>{callerData.status}</CTableDataCell>
                        </CTableRow>
                      </CTableBody>
                    </CTable>
                  </div>
                </CCardBody>
              </CCard>

              <CallerApps />

              <CCard className="mb-4" style={{ width: "95%", margin: "20px" }}>
                <CCardHeader style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <strong>Send Message</strong>{ }
                  </div>
                </CCardHeader>
                <CCardBody>
                  <div>
                    <CForm onSubmit={(e) => sendMessage(e)}>
                      <div className="mb-3">
                        <CFormLabel htmlFor="exampleFormControlInput1">Email address</CFormLabel>
                        <CFormInput type="email" id="exampleFormControlInput1" value={callerData.email} />
                      </div>
                      <div className="mb-3">
                        <CFormLabel htmlFor="exampleFormControlTextarea1">Message</CFormLabel>
                        <CFormTextarea id="message" rows="3" required></CFormTextarea>
                      </div>
                      <CCol xs={12}>
                        <CButton type="submit">Send</CButton>
                      </CCol>
                    </CForm>
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
        }
      </CRow>
      {/* Pop-Up */}
      <CModal size="xl" visible={visibleXL} onClose={() => setVisibleXL(false)}>
        <CModalHeader onClose={() => setVisibleXL(false)}>
          <CModalTitle>Modal title</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <strong>S.No : </strong>{specificData.id}
          <br />
          <strong>Mobile : </strong>{specificData.mobile_number}
          <br />
          <strong>Institute : </strong>{specificData.school_name}
          <br />
          <strong>Institute Address : </strong>{specificData.school_address}
          <br />
          <strong>Call Attempts : </strong>{specificData.call_attempts}
          <br />
          <strong>Call Status : </strong>{specificData.call_status}
          <br />
          <strong>Call Summary : </strong>{specificData.call_summary}
          <br />
          <strong>In-Charge Name : </strong>{specificData.incharge_name}
          <br />
          <strong>In-Charge Designation : </strong>{specificData.incharge_designation}
          <br />
          <strong>Current Teaching Method : </strong>{specificData.current_teaching_method}
          <br />
          <strong>Other Platform : </strong>{specificData.other_platform}
          <br />
          <strong>In-Charge Response : </strong>{specificData.incharge_response}
          <br />
          <strong>Caller Opinion on In-Charge : </strong>{specificData.tele_caller_opinion}
        </CModalBody>
        <CModalFooter>
          <CButton color="warning" onClick={() => setVisibleXL(false)}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>

      {/* All Data */}
      {/* <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
          <CCardHeader style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <strong>All </strong> <small>Data Table</small>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <input type="date" id="dateWise" name="dateWise" onChange={ showDateWiseData } />
              <CButton color="dark" size="sm" onClick={ getData }>Show All</CButton>
            </div>
          </CCardHeader>
            <CCardBody>
              <div>
                <CTable bordered>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col">#</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Mobile</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Call Status</CTableHeaderCell>
                      <CTableHeaderCell scope="col">School</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Address</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Teaching Method</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Other Platform</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Response</CTableHeaderCell>
                      <CTableHeaderCell scope="col">More</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {
                      allData.map((data) =>
                        <CTableRow key={data.id}>
                          <CTableHeaderCell scope="row">{data.id}</CTableHeaderCell>
                          <CTableDataCell>{data.mobile_number}</CTableDataCell>
                          <CTableDataCell>{data.call_status}</CTableDataCell>
                          <CTableDataCell style={{ width: "15%" }}>{data.school_name}</CTableDataCell>
                          <CTableDataCell style={{ width: "20%" }}>{data.school_address}</CTableDataCell>
                          <CTableDataCell>{data.current_teaching_method}</CTableDataCell>
                          <CTableDataCell>{data.other_platform}</CTableDataCell>
                          <CTableDataCell>{data.incharge_response}</CTableDataCell>
                          <CTableDataCell><CButton color="success" variant="outline" onClick={() => showSpecificData(data.application, data.id)}>More Info</CButton></CTableDataCell>
                        </CTableRow>
                      )
                    }
                  </CTableBody>
                </CTable>
              </div>
              <CPagination align="end" aria-label="Page navigation example">
                {
                  // If Previous Page URL is null
                  previousPagination === null ?
                  <CPaginationItem disabled>Previous</CPaginationItem> :
                  <CPaginationItem onClick={() => previousPage(previousPagination)} style={{ cursor: "pointer" }}>Previous</CPaginationItem>
                }
                {
                  // If Next  Page URL is null
                  nextPagination === null ?
                  <CPaginationItem onClick={() => nextPage(nextPagination)} disabled>Next</CPaginationItem> : 
                  <CPaginationItem onClick={() => nextPage(nextPagination)} style={{ cursor: "pointer" }}>Next</CPaginationItem>
                }
              </CPagination>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow> */}
    </>
  )
}

export default CallerData