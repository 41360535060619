import React from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import {
  cilPhone,
  cilEnvelopeClosed,
} from '@coreui/icons'

const Support = () => {

  return (
    <>
      {/* All Data */}
      <CRow className='centerColumn mt-4 mb-4'>
        <CCol xs={11}>
          <CCard className="mb-4" style={{ textAlign: "left" }}>
          <CCardHeader style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h3>Contact us</h3>
            </div>
          </CCardHeader>
            <CCardBody>
              <div className="mb-3">
                <h4>Phone:</h4>
              </div>
              <div className="mb-2">
                <CIcon icon={cilPhone} customClassName="nav-icon" style={{ width: "30px" }}/>
                <a href="tel:+918142570195" style={{ textDecoration: "none", fontWeight: '500' }}> +918142570195</a>
              </div>
              <div className="mb-4">
                <CIcon icon={cilPhone} customClassName="nav-icon" style={{ width: "30px" }}/>
                <a href="tel:+916303871929" style={{ textDecoration: "none", fontWeight: '500' }}> +916303871929</a>
              </div>
              <div className="mt-4 mb-3">
                <h4>Email:</h4>
              </div>
              <div className="mb-4">                
                <CIcon icon={cilEnvelopeClosed} customClassName="nav-icon" style={{ width: "30px" }}/>
                <a href="mailto: support@indgiants.com" style={{ textDecoration: "none", fontWeight: '500' }}> support@indgiants.com</a>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default Support