import { React, useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CAlert
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import axios from 'axios'
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {

  function reCaptchaonChange(value) {
    // console.log("Captcha value:", value);
    console.log("Recaptcha Success")
  }

  const recaptchaRef = useRef();

  const history = useNavigate();
  const TokenKey = localStorage.getItem('session_token')
  // console.info(TokenKey)

    const [hasTokenKey, sethasTokenKey] = useState([])
    const getToken = async() => {
      if (TokenKey) {
        let userURL = await fetch("https://api-admin.indgiants.in/api/login", {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${TokenKey}`
          },
        })        
        // const userData = await userURL.json()
        .then((res) => {
          if (res.status === 200) {
            history.push('/applications')
            window.location.reload();
          }else{
            return
          }
        })
      } else {
        return
      }
    }

    // const ErrorAlert = document.getElementById('error_msg')
    const [errorMsgAlert, seterrorMsgAlert] = useState('')
    const [alertBox, setalertBox] = useState(false)

    const loginFunction = () => {
      let email = document.getElementById("userEmail").value;
      let password = document.getElementById("userPassword").value;
      // let loginRecaptcha = recaptchaRef.current.getValue();

  
      // Should use same Key values from API
      const userCredentials = { email, password }
  
      // console.info(userCredentials)
      axios.post("https://api-admin.indgiants.in/api/login", userCredentials )
      .then(res => {
        if (res.status === 200) {
          // console.info(res.data.token)
          localStorage.setItem("session_token", res.data.session_token);
          // history.push("/applications")
          window.location.reload(false);
          // console.info(res)
        } else {
          return
        }
      })
      .catch((error) => {
        error = error.response.data.message;
        setalertBox(true)
        seterrorMsgAlert(error);
      })
    }

    useEffect(() => {
      getToken()
    }, [])


  // let TokenKey = localStorage.getItem("token")
  // console.info(TokenKey)

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      {
        (!hasTokenKey) ?
          history.push("/")
        :
        <>
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md={6}>
                <CCardGroup>
                  <CCard className="p-4">
                    <CCardBody>
                      <CForm className='justify-content-left'>
                        <h1 style={{ textAlign: "left" }}>Login</h1>
                        <p className="text-medium-emphasis" style={{ textAlign: "left" }}>Sign In to your account</p>
                        {
                          alertBox ? 
                          <CAlert color="danger" id="error_msg">
                            { errorMsgAlert }
                          </CAlert>
                        :
                        null
                        }
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilUser} />
                          </CInputGroupText>
                          <CFormInput placeholder="Username" autoComplete="username" id="userEmail" />
                        </CInputGroup>
                        <CInputGroup className="mb-4">
                          <CInputGroupText>
                            <CIcon icon={cilLockLocked} />
                          </CInputGroupText>
                          <CFormInput
                            type="password"
                            placeholder="Password"
                            autoComplete="current-password"
                            id="userPassword"
                          />
                        </CInputGroup>
                        <CInputGroup>
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6Le1wBkdAAAAAPZ3EXHoIzxQU1JHeyp8oGEm2C56"
                            onChange={reCaptchaonChange}
                          />
                        </CInputGroup>
                        <CRow className="mt-3">
                          <CCol xs={6} style={{ textAlign: "left" }}>
                            <CButton color="primary" className="px-4" onClick={loginFunction}>
                              Login
                            </CButton>
                          </CCol>
                          {/* <CCol xs={6} className="text-right">
                            <CButton color="link" className="px-0">
                              Forgot password?
                            </CButton>
                          </CCol> */}
                        </CRow>
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CCardGroup>
              </CCol>
            </CRow>
          </CContainer>
        </>
      }
    </div>
    
  )
}

export default Login