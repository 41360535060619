import React, { useState, useEffect } from 'react'
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CTable,
    CTableBody,
    CTableRow,
    CSpinner,
    CFormSwitch
} from '@coreui/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { forEach } from 'core-js/core/array';

toast.configure();

const CallerApps = () => {

    const callerID = localStorage.getItem("caller_id")
    const callerEmail = localStorage.getItem("caller_email")
    const [Loading, setLoading] = useState(true)
    const [allApps, setAllApps] = useState([])
    const [caller_apps, setCaller_Apps] = useState([])
    const [allocatedApps, setAllocatedApps] = useState([])
    const [misAllocatedApps, setMisAllocatedApps] = useState([])
    const [totalApps, setTotalApps] = useState([])

    const getApps = async () => {
        let url = await fetch("https://api-admin.indgiants.in/api/get-applications", {
            method: "GET"
        })
            .then((res) => res.json())
            .then((res) => {
                if (res != undefined) {
                    setAllApps(res)
                    // console.info(res)
                }
            }
            )
            .catch((err) => null)
    }

    const getCallerApps = async (callerID) => {
        const userData = new FormData();
        userData.append("caller_id", callerID)
        let url = await fetch("https://api-admin.indgiants.in/api/caller-apps", {
            method: "POST",
            body: userData
        })
            .then((res) => res.json())
            .then((res) => {
                if (res != undefined) {
                    setCaller_Apps(res)
                    setLoading(false)
                    // console.log(res)
                }
            }
            )
            .catch((err) => setLoading(false))
    }

    const misallocateApp = async (app_id) => {
        const appID = new FormData();
        appID.append("id", app_id)
        let url = await fetch("https://api-admin.indgiants.in/api/misallocate-app", {
            method: "POST",
            body: appID
        })
            .then((res) => res.json())
            .then((res) => {
                console.info(res)
            }
            )
            .catch((err) => setLoading(false))
    }

    useEffect(() => {
        getCallerApps(callerID);
        getApps()
        console.info(totalApps)
    }, [callerID])

    const allocateApp = async (Assign_callerAPP) => {
        const assignForm = new FormData();
        assignForm.append("caller_id", callerID)
        assignForm.append("caller_email", callerEmail)
        assignForm.append("caller_app", Assign_callerAPP)
        let url = await fetch("https://api-admin.indgiants.in/api/allocate-app", {
            method: "POST",
            body: assignForm
        })
            .then((res) => {
                console.info(res)
            })
            .catch((error) => {
                console.info(error)
            })
    }

    useEffect(() => {
        getCallerApps(callerID);
        getApps()
    }, [callerID])

    function getDifference(allApps, caller_apps) {
        return allApps.filter(allApps => {
            return !caller_apps.some((caller_apps) => {
                return allApps.app === caller_apps.caller_app;
            });
        });
    }

    const [remaining_apps, setremaining_apps] = useState([])
    // getDifference(allApps, caller_apps).map((item) => {
    //     console.info(item)
    // })
    // console.info(result)
    useEffect(() => {
        // setremaining_apps(result)
        // console.info(result)
    }, [])
    return (
        <>
            <CRow>
                {
                    Loading ?
                        <CSpinner color="dark" style={{ margin: "20px" }} />
                        :
                        <CCol xs={12}>
                            <CCard className="mb-4" style={{ width: "95%", margin: "20px" }}>
                                <CCardHeader style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <strong>APPs </strong>{ }
                                    </div>
                                </CCardHeader>
                                <CCardBody>
                                    <div>
                                        {
                                            caller_apps.map((data) =>
                                                <CTableRow key={data.id}>
                                                    <>
                                                        <CFormSwitch size="lg" label={data.caller_app} id="formSwitchCheckChecked" defaultChecked onChange={() => misallocateApp(data.id)}/>
                                                    </>
                                                </CTableRow>
                                            )
                                        }
                                    </div>
                                    <div>
                                        {
                                            getDifference(allApps, caller_apps).map((item) =>
                                                <CTableRow key={item.id}>
                                                    <>
                                                        <CFormSwitch size="lg" label={item.app} onChange={() => allocateApp(item.app)} />
                                                    </>
                                                </CTableRow>
                                            )
                                        }
                                    </div>
                                </CCardBody>

                            </CCard>
                        </CCol>
                }
            </CRow>

        </>
    )
}

export default CallerApps;