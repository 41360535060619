import './App.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import Routing from "./components/Routing";

function App() {
  return (
    <div className="App">
      <Routing />
    </div>
  );
}

export default App;
