import React from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react';

const About = () => {

  return (
    <>
      {/* All Data */}
      <CRow className='centerColumn mt-4 mb-4'>
        <CCol xs={11}>
          <CCard>
          <CCardHeader style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
                <h3>Youth Empowerment Drive: Connecting India through Franchises</h3>
            </div>
          </CCardHeader>
            <CCardBody style={{ textAlign: "left" }}>
              <div className="mb-4">
                Indgiants Pvt Ltd is an Indian firm providing integrated franchise solutions by setting up its franchises throughout India through its ‘Youth Empowerment Drive’. Having established in 2020 with an absolute authority on franchising and licensing is helping helped hundreds of investors in setting up franchises with a promising ROI on their investments. It is the first franchising company in India that is offering equal shares to its franchises.
              </div>
              <div className="mb-4">                
                Indgiants Education Services: Indgiants facilitates the pursuit of education online through web and mobile applications providing a simple integrated solution which is easy to understand and accessible to all.
              </div>
              <div>
                <ul>
                  <li className="mb-2">
                    Indgiants has launched an earning Portal http://mindhosts.com to provide End-to-end Education Solutions for the New Normal through to Schools, Colleges, Coaching Institutes, Tuition Centers and Individual Trainers and Tuition Teachers. These portals have been designed using the state-of-the-art technology where the faculty can continue their classes using their mobiles and students can take the classes through their mobiles.
                  </li>
                  <li className="mb-2">
                    Indgiants is transforming the academic world into a ‘Global Classroom’. It has launched a mobile application called ‘Lecturersclub’ through its exclusive eLearning Portal http://lecturersclub.com that transforms academicians into ‘Global Trainers’ through the unique features of its ‘Mobile Classrooms’. The Lecturersclub APP is a technovation of Indgiants to Transform India into a Developed Nation. Lecturersclub supports every trainer Where, we put technology into every Trainer’s pocket.
                  </li>
                  <li className="mb-2">
                    Indgiants has launched the first eCommerce portal in India to provide exclusive education material, educational equipment and medical consumables and medical equipment
                  </li>
                  <li className="mb-2">
                    Indgiants trusts in rendering the best customer experience through its “Make in India – Vocal for Global” policy by enabling Indian entrepreneurs to expand their business Pan-India through the concept of Franchising to empower youth. Subsequently Indgiants envisions to create employment opportunities to youth.
                  </li>
                  <li className="mb-2">
                    Indgiants Mission: The 3 C’s (Cognizance, Creativity and Connectivity) are the basic foundation blocks on which Indgiants is being raised to realize its vision.
                  </li>
                </ul>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default About