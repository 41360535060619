import React, { useEffect, useState } from 'react'

import {
    CForm,
    CButton,
    CFormLabel,
    CFormInput,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CSpinner,
} from '@coreui/react'
import { toast } from 'react-toastify';
import axios from 'axios'

toast.configure();

// const WidgetsDropdown = lazy(() => import('../widgets/WidgetsDropdown.js'))
// const WidgetsBrand = lazy(() => import('../widgets/WidgetsBrand.js'))

const Applications = () => {

    const [applicationData, setApplicationData] = useState([])
    //For Showing All Data
    const getApplications = async () => {
        let appData = await fetch("https://api-admin.indgiants.in/api/get-applications");
        appData = await appData.json();
        setApplicationData(appData);
    }

    const [app_added, setApp_added] = useState(false)
    useEffect(() => {
        getApplications()
    }, [app_added])
  

    const submitApplication = (e) => {
        e.preventDefault();
        const app = document.getElementById('appInput').value;

        // Should use same Key values from API
        const applicationName = { app }
    
        if (app != null) {
            // console.info(userCredentials)
            axios.post("https://api-admin.indgiants.in/api/add-application", applicationName )
            .then(res => {
                if (res.status === 200) {
                    toast.success("Application Added Succefully.");
                    setApp_added(true)
                } else {
                return
                }
            })
            .catch((error) => {
                error = error.response.data.message;
            })
        }else{
            return
        }
    }

    
    return (
        <React.Fragment>
            {
                applicationData.length != 0 ?
                <div>
                <CTable striped bordered style={{ width:"80%", margin: "20px", textAlign: 'left' }}>
                <CTableHead>
                    <CTableRow>
                    <CTableHeaderCell scope="col">Applications</CTableHeaderCell>
                </CTableRow>
                </CTableHead>
                <CTableBody>
                        {
                            applicationData.map((app) =>
                                <CTableRow key={app.id}>
                                    <CTableDataCell>{app.app}</CTableDataCell>
                                </CTableRow>
                            )
                        }
                </CTableBody>
                </CTable>

                <CForm onSubmit={(e) => submitApplication(e)} style={{ width:"80%", padding: "20px", textAlign: 'left' }}>
                    <div className="mb-3">
                        <CFormLabel htmlFor="addApp">Add Application</CFormLabel>
                        <CFormInput type="text" id="appInput" aria-describedby="emailHelp" />
                    </div>
                    <CButton type="submit" color="primary">
                        Submit
                    </CButton>
                </CForm>
                </div>
                :
                <div>
                    <CSpinner style={{ margin: "20px" }} />
                </div>
            }
        </React.Fragment>
    )
}

export default Applications
